import React from 'react';
import styled from 'styled-components';

import {
  AddSlideDialog,
  ExternalLink,
  Headline,
  SideBar,
  SideBarItem,
  SlideListItem,
  SlidesTemplate,
} from 'components';
import config from 'config';
import {
  businessStore,
  interact,
  locationsStore,
  slidesStore,
} from 'services/interact';
import styles from 'style-guide';

const StyledHeadline = styled(Headline)`
  background-color: ${styles.colors.gs.gray};
  color: ${styles.colors.gs.white};
  padding: 4px 8px;
`;

export default function SlidesPage() {
  const business = businessStore.use('business');
  const current = locationsStore.use('current');
  const isUploading = slidesStore.use('uploading');
  const locations = locationsStore.use('locations');
  const slides = slidesStore.use('slides');

  const baseUrl = config('URLS', 'domain');

  return (
    <SlidesTemplate
      column1={
        <div>
          <StyledHeadline tag="h2">Slides</StyledHeadline>
          <AddSlideDialog
            isUploading={isUploading}
            onAdd={(dataURL) => interact('slides/addSlide', dataURL)}
          />
          <div>
            {slides.map((slide) => (
              <SlideListItem
                key={slide.id}
                onLengthChange={(id, length) =>
                  interact('slides/changeLength', { id, length })
                }
                onMoveEarlier={(id) => interact('slides/moveEarlier', id)}
                onMoveLater={(id) => interact('slides/moveLater', id)}
                onRemove={(id) => {
                  if (window.confirm('You are about to remove this slide.')) {
                    interact('slides/remove', id);
                  }
                }}
                slide={slide}
                slideCount={slides.length}
              />
            ))}
          </div>
        </div>
      }
      column2={
        <div>
          <StyledHeadline tag="h2">Links</StyledHeadline>
          <p>
            To display your slides, visit the following link on your TV:
            <br />
            {business && current ? (
              <ExternalLink
                href={`${baseUrl}/slides/${business.id}/${current.id}`}
              >
                {baseUrl}/slides/{business.id}/{current.id}
              </ExternalLink>
            ) : null}
          </p>
        </div>
      }
      sidebar={
        <SideBar>
          {locations.map((location) => (
            <SideBarItem
              key={location.id}
              onClick={() => interact('locations/changeLocation', location.id)}
              selected={current && location.id === current.id}
            >
              {location.name}
            </SideBarItem>
          ))}
          {/* <SideBarItem icon="add" onClick={() => console.log('add location')}>
            Add Location
          </SideBarItem> */}
        </SideBar>
      }
    />
  );
}
