import { fromJS, Map } from 'immutable';
import { useEffect, useState } from 'react';

import StoreInternals from './StoreInternals';

function Store(initial) {
  this.selectors = {};
  this.id = new Date().getTime();
  this.interactions = {};
  this.internals = new StoreInternals(this);
  this.state = Map(initial);
  this.subscriberKey = 0;
  this.subscribers = {};
}

Store.prototype = {
  /**
   * Adds an interaction to listen to
   * @param string interaction The interaction name
   * @param function sideEffect The side effect function to call
   *   The side effect function must return a reducer function
   *
   *   store.addInteraction((payload) => {
   *     await someSideEffect(payload);
   *     return (state) => state;
   *   });
   */
  addInteraction: function(interaction, sideEffect) {
    this.interactions[interaction] = sideEffect;
  },

  /**
   * Adds a selector for state data
   * @param string selector The name of the selector
   * @param function cb The function that gets called
   *   The state will always be the first argument passed to cb, followed by
   *   any additional arguments passed to store.select
   *
   *   store.addSelector('someValue', (state, filter) =>
   *     state.get('someValue').filter(filter)
   *   );
   *
   *   store.select('someValue', 'someFilter');
   */
  addSelector: function(selector, cb) {
    this.selectors[selector] = cb;
  },

  /**
   * Selects the state data specified
   * @param string selector The selector to select
   * @param ...args Arguments to pass to the selector
   */
  select: function(selector, ...args) {
    if (this.selectors[selector]) {
      return this.selectors[selector](this.state, ...args);
    } else if (this.state.has(selector)) {
      return this.state.get(selector);
    } else {
      throw new Error(`Invalid selector: "${selector}"`);
    }
  },

  /**
   * Subscribe to the store
   * These callbacks get called when the store's state changes
   * @param function cb The callback to call
   */
  subscribe: function(cb) {
    const key = this.subscriberKey++;
    this.subscribers[key] = cb;

    return () => {
      delete this.subscribers[key];
    };
  },

  /**
   * Hook to access the store's state
   * @param string selector The selector to use to access the state
   * @param ...args Arguments to pass to the selector
   */
  use: function(selector, ...args) {
    const getState = this.select.bind(this, selector, ...args);
    const [state, setState] = useState(getState());

    // to keep us from resubscribing every render, we will track the args and
    // state in immutable refs and use them as comparison objects in useEffect
    const argsRef = this.internals.useImmutableRef(args);
    const stateRef = this.internals.useImmutableRef(state);

    useEffect(
      () =>
        this.subscribe(() => {
          const nextState = getState();
          if (nextState !== null && typeof nextState === 'object') {
            const ns = fromJS(nextState);
            if (ns && !ns.equals(stateRef.current)) {
              setState(nextState);
            }
          } else {
            if (nextState !== state) {
              setState(nextState);
            }
          }
        }),
      [selector, argsRef.current, stateRef.current]
    );

    return state;
  },
};

export default Store;
