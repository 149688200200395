import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  && {
    border: 1px solid #eee;
    box-sizing: border-box;
    display: inline-block;
    flex: 0;
    margin: 0;
    padding: 0;
    max-height: 108px;
    max-width: 192px;
    min-height: 108px;
    min-width: 192px;
  }
`;

AdPreview.propTypes = {
  src: string.isRequired,
};

export default function AdPreview({ src }) {
  return (
    <Wrapper>
      <img alt="Ad" height="108" src={src} width="192" />
    </Wrapper>
  );
}
