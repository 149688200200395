import React from 'react';

import { Headline, LoginTemplate, Logo, SlidesLoginForm } from 'components';

export default function SlidesLoginPage() {
  return (
    <LoginTemplate
      form={<SlidesLoginForm />}
      headline={<Headline>ShadowVision Slides</Headline>}
      logo={<Logo large />}
    />
  );
}
