import { bool, func, node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.form`
  > * {
    display: block;
  }

  > button {
    margin-top: 28px;
  }
`;

Form.propTypes = {
  children: node.isRequired,
  fullWidth: bool,
  onSubmit: func,
};

export default function Form({ children, onSubmit, ...props }) {
  children = React.Children.map(children, (child) =>
    React.cloneElement(child, { ...child.props, ...props })
  );

  return <Wrapper onSubmit={onSubmit}>{children}</Wrapper>;
}
