import { Button } from '@material-ui/core';
import { object, func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ImageListItem } from 'components';
import styles from 'style-guide';

const ErrorButton = styled(Button)`
  button& {
    color: ${styles.colors.error.string};
  }
`;

FillerListItem.propTypes = {
  ad: object.isRequired,
  onRemove: func.isRequired,
};

export default function FillerListItem({ ad, onRemove }) {
  return (
    <ImageListItem src={ad.src}>
      <ErrorButton onClick={() => onRemove(ad.id)}>Remove</ErrorButton>
    </ImageListItem>
  );
}
