import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import {
  NotFoundPage,
  ProtectedRoute,
  SlidesLoginPage,
  SlidesPage,
  ViewSlidePage,
} from 'components';
import { ThemeProvider } from 'style-guide';

window.MAX_IMAGES = 14;

export default function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/slides/login" />}
          />
          <Route exact path="/slides/login" component={SlidesLoginPage} />
          <ProtectedRoute
            exact
            loginPage="/slides/login"
            path="/slides"
            component={SlidesPage}
          />
          <Route
            exact
            path="/slides/:business_id/:location_id"
            component={ViewSlidePage}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}
