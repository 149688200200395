import { shape, string } from 'prop-types';
import React, { useEffect } from 'react';

import { Ad, Fade, ViewSlideTemplate } from 'components';
import { interact, slidesStore } from 'services/interact';

ViewSlidePage.propTypes = {
  match: shape({
    params: shape({
      id: string,
      zip: string,
    }),
  }),
};

export default function ViewSlidePage({
  match: {
    params: { business_id, location_id },
  },
}) {
  useEffect(() => {
    interact('slides/viewSlides', { business_id, location_id });
  }, [business_id, location_id]);

  const current = slidesStore.use('current');
  const slides = slidesStore.use('slides');

  const length = current ? current.length * 1000 : 3000;
  useEffect(() => {
    const timeout = setTimeout(
      () => interact('slides/displayNextSlide'),
      length
    );
    return () => clearTimeout(timeout);
  });

  return (
    <ViewSlideTemplate>
      {slides.map((slide) => (
        <Fade absolute key={slide.id} visible={slide.id === current.id}>
          <Ad src={slide.src} />
        </Fade>
      ))}
    </ViewSlideTemplate>
  );
}
