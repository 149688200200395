import { node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import styles from 'style-guide';

const Wrapper = styled.span`
  font-size: ${styles.fonts.small.size};
`;

InformationalText.propTypes = {
  children: node,
};

export default function InformationalText({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
