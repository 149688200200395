import { fromJS } from 'immutable';
import { useRef } from 'react';

function StoreInternals(store) {
  this.store = store;
}

StoreInternals.prototype = {
  /**
   * Processes the given interaction and payload
   * @param string interaction The current interaction to process
   * @param mixed payload The payload of the interaction
   * @return mixed
   */
  process: async function(interaction, payload) {
    if (this.store.interactions[interaction]) {
      return {
        cb: await this.store.interactions[interaction](payload),
        store: this.store,
      };
    }

    return null;
  },

  /**
   * Sets the store's state and calls any subscribed callbacks
   * @param Map newState The reduced state
   */
  setState: function(newState) {
    this.store.state = newState;

    // calling the subscribers can change the list of subscribers, so we will
    // copy the subscribers list to make sure all subscribers get called
    const subscribers = [];
    for (let key in this.store.subscribers) {
      subscribers.push(this.store.subscribers[key]);
    }

    subscribers.forEach((subscriber) => subscriber());
  },

  /**
   * Gets an immutable reference for the specified item
   * @param mixed item The item to reference
   * @return object
   */
  useImmutableRef: function(item) {
    const ref = useRef();
    const immutable = fromJS(item);
    if (immutable && immutable.equals) {
      if (!immutable.equals(ref.current)) {
        ref.current = immutable;
      }
    } else if (immutable !== ref.current) {
      ref.current = immutable;
    }

    return ref;
  },
};

export default StoreInternals;
