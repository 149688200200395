import { func, number, object } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { IconButton, ImageListItem, SecondsInput } from 'components';

const PositionWrapper = styled.div`
  align-items: center;
  display: flex;

  > span {
    margin-right: 8px;
  }
`;

SlideListItem.propTypes = {
  onLengthChange: func.isRequired,
  onMoveEarlier: func.isRequired,
  onMoveLater: func.isRequired,
  onRemove: func.isRequired,
  slide: object.isRequired,
  slideCount: number.isRequired,
};

export default function SlideListItem({
  onLengthChange,
  onMoveEarlier,
  onMoveLater,
  onRemove,
  slide,
  slideCount,
}) {
  return (
    <ImageListItem src={slide.src}>
      <PositionWrapper>
        <IconButton
          disabled={slide.order === 0}
          icon="arrow_left"
          onClick={() => onMoveEarlier(slide.id)}
          small
        />
        <IconButton
          disabled={slide.order === slideCount - 1}
          icon="arrow_right"
          onClick={() => onMoveLater(slide.id)}
          small
        />
        <IconButton icon="delete" onClick={() => onRemove(slide.id)} small />
      </PositionWrapper>
      <SecondsInput
        defaultValue={'' + slide.length}
        onInput={(length) => onLengthChange(slide.id, length)}
      />
    </ImageListItem>
  );
}
