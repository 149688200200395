import { useEffect, useState } from 'react';

import Flux from './Flux';
import Store from './Store';

import authStore from './stores/auth';
import businessStore from './stores/business';
import locationsStore from './stores/locations';
import slidesStore from './stores/slides';

const flux = new Flux();

flux.addStore(authStore);
flux.addStore(businessStore);
flux.addStore(locationsStore);
flux.addStore(slidesStore);

export { authStore, businessStore, locationsStore, slidesStore };

/**
 * Use hooks to create a mini-store on the fly
 * @param object
 *   initial The initial state of the store
 *   interactions The key-value pair of interactions and callbacks
 */
const useInteractions = ({ initial, interactions }) => {
  // load the store or create if necessary
  let [store, setStore] = useState(null);
  if (store === null) {
    store = new Store(initial);

    for (let key in interactions) {
      store.addInteraction(key, interactions[key]);
    }

    setStore(store);
  }

  // add the store to the flux system
  useEffect(() => {
    flux.addStore(store);
    return () => flux.removeStore(store);
  }, [store]);

  // expose every key in the state
  const state = {};
  for (let key in initial) {
    state[key] = store.use(key);
  }

  return state;
};

const interact = flux.interact.bind(flux);
export { interact, useInteractions };
