import Logger from './Logger';

function Flux() {
  this.interaction = Promise.resolve();
  this.logger = new Logger(console);
  this.stores = [];
}

Flux.prototype = {
  /**
   * Adds a store to the flux system
   * @param Store store The store to add
   */
  addStore: function(store) {
    this.stores.push(store);
  },

  /**
   * Interact with the flux system
   * @param string interaction The interaction to trigger
   * @param mixed payload The interaction's payload
   */
  interact: async function(interaction, payload) {
    await this.interaction;
    return (this.interaction = this.triggerInteraction(interaction, payload));
  },

  /**
   * Removes the given store from the flux system
   * @param Store store The store to remove
   */
  removeStore: function(store) {
    this.stores = this.stores.filter((s) => s.id !== store.id);
  },

  /**
   * Schedules an interaction so they happen in the correct order
   * @param string interaction The interaction to trigger
   * @param mixed payload The interaction's payload
   */
  triggerInteraction: function(interaction, payload) {
    // wrap in promise so we can await the result
    return new Promise(async (resolve) => {
      const lastFour = interaction.substr(-4);
      if (lastFour !== '/pre' && lastFour !== '/err') {
        await this.triggerInteraction(interaction + '/pre', payload);
      }

      // start processing the side-effects
      let promises = [];
      this.stores.forEach((store) => {
        promises.push(store.internals.process(interaction, payload));
      });

      // wait for the side-effects to finish
      return Promise.all(promises)
        .then((reducers) => {
          this.logger.start(interaction, payload);

          // now reduce the state for any applicable reductions
          reducers
            .filter((reducer) => !!reducer && reducer.cb)
            .forEach((reducer) => {
              const oldState = reducer.store.state;
              const newState = reducer.cb(oldState);

              if (!oldState.equals(newState)) {
                this.logger.addDiff(oldState.toJS(), newState.toJS());
                reducer.store.internals.setState(newState);
              }
            });

          this.logger.flush();
        })
        .catch(async (err) => {
          await this.triggerInteraction(interaction + '/err', {
            err,
            payload,
          });
        })
        .finally(resolve);
    });
  },
};

export default Flux;
