import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components';
import styles from 'style-guide';

const IconWrapper = styled(ListItemIcon)`
  && {
    margin-right: 0;
  }
`;

const ListItemButton = styled(ListItem)`
  && [class*='MuiListItemText'],
  && .material-icons {
    color: ${styles.colors.primary.string};
  }
`;

const StyledListItem = styled(ListItem)`
  &&[class*='MuiListItem-selected'],
  &&[class*='MuiListItem-selected']:hover {
    background-color: ${styles.colors.primary.string};
  }

  &[class*='MuiListItem-selected'] [class*='MuiListItemText'] {
    color: ${styles.colors.primary.textColor};
  }
`;

SideBarItem.defaultProps = {
  selected: false,
};

SideBarItem.propTypes = {
  children: string.isRequired,
  icon: string,
  onClick: func,
  selected: bool,
};

export default function SideBarItem({ children, icon, onClick, selected }) {
  if (icon) {
    return (
      <ListItemButton button onClick={onClick}>
        <IconWrapper>
          <Icon icon={icon} />
        </IconWrapper>
        <ListItemText primary={children} />
      </ListItemButton>
    );
  }

  return (
    <StyledListItem button onClick={onClick} selected={selected}>
      <ListItemText primary={children} />
    </StyledListItem>
  );
}
