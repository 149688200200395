import { node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AdPreview } from 'components';

const Wrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  margin: 16px 0;
  width: 434px;

  &:nth-of-type(odd) {
    margin-right: 16px;
  }

  > div {
    align-items: flex-start;
    display: inline-flex;
    flex: 1;
    flex-flow: column nowrap;
    margin: 0 16px;
  }
`;

ImageListItem.propTypes = {
  children: node.isRequired,
  src: string.isRequired,
};

export default function ImageListItem({ children, src }) {
  return (
    <Wrapper>
      <AdPreview src={src} />
      <div>{children}</div>
    </Wrapper>
  );
}
