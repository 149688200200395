let config = {
  URLS: {
    api: 'https://api.shadow.vision',
    domain: 'https://app.shadow.vision',
  },
};

if (process.env.NODE_ENV === 'development') {
  config.URLS.api = 'http://localhost:8888';
  config.URLS.domain = 'http://localhost:3000';
}

export default (key, value = null, defaultValue = null) => {
  const category = config[key] || [];
  if (value === null) {
    return category;
  }

  return category[value] || defaultValue;
};
