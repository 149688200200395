import { fromJS, List } from 'immutable';

import { interact } from 'services/interact';
import spanreed from 'services/spanreed';
import Store from '../Store';

const store = new Store({
  currentId: null,
  locations: List(),
});

store.addInteraction('auth/login', () => (state) => {
  interact('locations/loadLocations');
  return state;
});

store.addInteraction('locations/changeLocation', (id) => (state) =>
  state.set('currentId', id)
);

store.addInteraction('locations/loadLocations', async () => {
  const response = await spanreed.graphql(
    `query loadLocations {
      locations {
        id
        name
      }
    }`
  );

  const locations = response.data.locations;
  interact('locations/changeLocation', locations[0].id);

  return (state) => state.set('locations', fromJS(locations));
});

store.addSelector('current', (state) => {
  const current = state
    .get('locations')
    .find((location) => location.get('id') === state.get('currentId'));
  if (current) {
    return current.toJS();
  }

  return null;
});
store.addSelector('locations', (state) => state.get('locations').toJS());

export default store;
