import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import styles from 'style-guide';

import { Form } from 'components';
import { authStore, interact } from 'services/interact';

const StyledForm = styled(Form)`
  margin: 0 auto;
  width: 300px;
`;

const ErrorSpan = styled(({ children, fullWidth, ...props }) => (
  <Collapse {...props}>{children}</Collapse>
))`
  color: ${styles.colors.error.string};
  margin-bottom: -8px;
  margin-top: 16px;
`;

export default function SlidesLoginForm() {
  const token = authStore.use('token');
  const loginErr = authStore.use('loginErr');

  if (token) {
    return <Redirect push to="/slides" />;
  }

  return (
    <StyledForm
      fullWidth
      onSubmit={(e) => {
        e.preventDefault();
        const email = e.target.querySelector('input[type="email"]').value;
        const password = e.target.querySelector('input[type="password"]').value;

        interact('auth/login', { email, password });
      }}
    >
      <TextField label="Email" required type="email" />
      <TextField label="Password" required type="password" />
      <ErrorSpan in={!!loginErr}>{loginErr}</ErrorSpan>
      <Button color="primary" type="submit" variant="contained">
        Log In
      </Button>
      {/* <Button color="secondary">Reset Password</Button> */}
    </StyledForm>
  );
}
