import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';

const H = styled(({ children, className, Tag }) => (
  <Tag className={className}>{children}</Tag>
))`
  display: block;
  font-family: Montserrat;
  font-weight: 300;
`;

Headline.defaultProps = {
  tag: 'h1',
};

Headline.propTypes = {
  children: node.isRequired,
  className: string,
  tag: string,
};

export default function Headline({ children, className, tag }) {
  return (
    <H className={className} Tag={tag}>
      {children}
    </H>
  );
}
