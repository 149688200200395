import { FormHelperText, Input, InputAdornment } from '@material-ui/core';
import { func, string } from 'prop-types';
import React, { useRef, useState } from 'react';
import { debounce } from 'lodash';

SecondsInput.propTypes = {
  defaultValue: string.isRequired,
  onInput: func.isRequired,
};

export default function SecondsInput({ defaultValue, onInput }) {
  let [value, setValue] = useState(defaultValue);
  let debounced = useRef(debounce(onInput, 700));

  return (
    <>
      <Input
        endAdornment={<InputAdornment position="end">seconds</InputAdornment>}
        onInput={(e) => {
          setValue(e.target.value);
          debounced.current(e.target.value);
        }}
        value={value}
      />
      <FormHelperText>Display Length</FormHelperText>
    </>
  );
}
