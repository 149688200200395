import { node, string } from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { authStore } from 'services/interact';

ProtectedRoute.defaultProps = {
  loginPage: '/',
};

ProtectedRoute.propTypes = {
  children: node,
  loginPage: string,
};

export default function ProtectedRoute({ children, loginPage, ...props }) {
  let pathName = window.location.pathname;
  let token = authStore.use('token');

  if (token) {
    return <Route {...props}>{children}</Route>;
  } else {
    return <Redirect to={loginPage + (`?go=${pathName}` || '')} />;
  }
}
