import { node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AppBar } from 'components';

const Background = styled.div`
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
`;

AppTemplate.propTypes = {
  children: node.isRequired,
};

export default function AppTemplate({ children }) {
  return (
    <Background>
      <AppBar />
      {children}
    </Background>
  );
}
