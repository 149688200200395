import { bool, func, node, string } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components';
import styles from 'style-guide';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? styles.colors.primary.textColor : 'inherit'};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 12px;
  transition: background-color 0.3s;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.7;
      pointer-events: none;
    `};

  > span {
    color: ${styles.fonts.color.string};
    display: inline-block;
    margin-left: 5px;
    transition: color 0.3s;
  }

  &:hover {
    background-color: ${styles.colors.primary.string};
  }

  &:hover > div,
  &:hover > span {
    color: ${styles.colors.primary.textColor};
  }
`;

IconButton.defaultProps = {
  disabled: false,
  selected: false,
};

IconButton.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  icon: string.isRequired,
  onClick: func.isRequired,
  selected: bool,
};

export default function IconButton({
  children,
  className,
  disabled,
  icon,
  onClick,
  selected,
}) {
  return (
    <Wrapper
      className={className}
      disabled={disabled}
      onClick={onClick}
      selected={selected}
    >
      <Icon icon={icon} />
      {children && <span>{children}</span>}
    </Wrapper>
  );
}
