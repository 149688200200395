import { node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100vw;

  > * {
    height: 100%;
    width: 100%;
  }
`;

ViewSlideTemplate.propTypes = {
  children: node,
};

export default function ViewSlideTemplate({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
