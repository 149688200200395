import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { bool, func } from 'prop-types';
import React from 'react';

import { Icon, ImageResizer } from 'components';
import { interact, useInteractions } from 'services/interact';

AddSlideDialog.propTypes = {
  isUploading: bool.isRequired,
  onAdd: func.isRequired,
};

export default function AddSlideDialog({ isUploading, onAdd }) {
  const { currentDataURL, isOpen, isProcessing } = useInteractions({
    initial: { currentDataURL: null, isOpen: false, isProcessing: false },
    interactions: {
      closeModal: () => (state) =>
        state.set('isProcessing', false).set('isOpen', false),
      endProcessing: () => (state) => state.set('isProcessing', false),
      openModal: () => (state) =>
        state.set('currentDataURL', null).set('isOpen', true),
      startProcessing: () => (state) => state.set('isProcessing', true),
      uploadImage: (dataURL) => (state) => state.set('currentDataURL', dataURL),
    },
  });

  return (
    <>
      <Button
        color="primary"
        onClick={() => interact('openModal')}
        style={{ marginTop: '12px' }}
        variant="contained"
      >
        <Icon icon="add" small />
        &nbsp;&nbsp;Add Slide
      </Button>
      <Dialog open={isOpen}>
        <DialogTitle>Add Slide</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please upload a 16:9 ratio image. If your image is bigger than
            3840x2160, it will be scaled down.
          </DialogContentText>
          <ImageResizer
            onChange={(dataURL) => interact('uploadImage', dataURL)}
            onProcessEnd={() => interact('endProcessing')}
            onProcessStart={() => interact('startProcessing')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => interact('closeModal')} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await onAdd(currentDataURL);
              interact('closeModal');
            }}
            color="primary"
            disabled={isProcessing || isUploading || !currentDataURL}
          >
            {isProcessing
              ? 'Processing...'
              : isUploading
              ? 'Uploading...'
              : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
