import { bool } from 'prop-types';
import React from 'react';

Logo.propTypes = {
  small: bool,
  large: bool,
  white: bool,
};

export default function Logo({ large, white }) {
  let size = large ? '300' : '80';
  white = white ? 'white-' : '';

  return (
    <img
      alt="logo"
      height={size}
      src={`/logo-${white}${size}x${size}.png`}
      width={size}
    />
  );
}
