import { arrayOf, bool, oneOfType, node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ vertically }) => vertically && 'align-items: center;'};
  display: flex;
  ${({ fullHeight }) => (fullHeight ? 'height: 100%;' : '')}
  ${({ horizontally }) => horizontally && 'justify-content: center;'};
`;

Center.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  fullHeight: bool,
  horizontally: bool,
  vertically: bool,
};

Center.defaultProps = {
  fullHeight: false,
};

export default function Center({
  children,
  fullHeight,
  horizontally,
  vertically,
}) {
  return (
    <Wrapper
      fullHeight={fullHeight}
      horizontally={horizontally}
      vertically={vertically}
    >
      {children}
    </Wrapper>
  );
}
