import { node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Center } from 'components';

const Background = styled.div`
  background-color: #fff;
  height: 100vh;
`;

const FormWrapper = styled.div`
  height: 224px;
  margin-left: 32px;
`;

const LogoWrapper = styled.div`
  height: 224px;
  width: 224px;

  > * {
    height: 100%;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  text-align: center;

  > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

LoginTemplate.propTypes = {
  form: node.isRequired,
  headline: node.isRequired,
  logo: node.isRequired,
};

export default function LoginTemplate({ form, headline, logo }) {
  return (
    <Background>
      <Center fullHeight horizontally vertically>
        <Wrapper>
          {headline}
          <LogoWrapper>{logo}</LogoWrapper>
          <FormWrapper>{form}</FormWrapper>
        </Wrapper>
      </Center>
    </Background>
  );
}
