import { fromJS, List } from 'immutable';

import { interact, locationsStore } from 'services/interact';
import LoopedList from 'services/LoopedList';
import spanreed from 'services/spanreed';
import Store from '../Store';

const store = new Store({
  current: null,
  list: null,
  slides: List(),
  uploading: false,
});

store.addInteraction('locations/changeLocation', () => (state) => {
  interact('slides/loadSlides');
  return state;
});

store.addInteraction('slides/addSlide/pre', () => (state) =>
  state.set('uploading', true)
);
store.addInteraction('slides/addSlide', async (dataURL) => {
  const response = await spanreed.graphql(
    `mutation addSlide(
      $data: String!,
      $length: Int!,
      $location_id: ID!,
      $order: Int!
    ) {
      slide: addSlide(
        slide: {
          data: $data,
          length: $length,
          location_id: $location_id,
          order: $order
        }
      ) {
        id
        length
        order
        src: url
      }
    }`,
    {
      data: dataURL,
      length: 30,
      location_id: locationsStore.select('current').id,
      order: store.select('slides').length,
    }
  );

  let slide = response.data.slide;
  slide.src = dataURL;

  return (state) => {
    return state
      .set('uploading', false)
      .update('slides', (slides) => slides.push(fromJS(slide)));
  };
});

store.addInteraction('slides/changeLength', async ({ id, length }) => {
  const response = await spanreed.graphql(
    `mutation changeLength($length: Int!, $slide: ID!) {
      slide: changeSlideLength(length: $length, slide: $slide) {
        id
        length
      }
    }`,
    { length: +length, slide: id }
  );

  return (state) => {
    let slides = state.get('slides');
    return state.set(
      'slides',
      slides.update(
        slides.findIndex((slide) => slide.get('id') === response.data.slide.id),
        (slide) => slide.set('length', response.data.slide.length)
      )
    );
  };
});

store.addInteraction('slides/displayNextSlide', () => (state) => {
  let current = store.select('current');
  let list = state.get('list');
  let node = list.find(current);

  return state.set('current', node ? node.next.data : null);
});

store.addInteraction('slides/loadSlides', async () => {
  const response = await spanreed.graphql(
    `query loadSlides($id: ID!) {
      slides(location_id: $id) {
        length
        id
        order
        src: url
      }
    }`,
    { id: locationsStore.select('current').id }
  );
  const slides = response.data.slides;

  return (state) => {
    let list = new LoopedList();

    slides.forEach((slide) => {
      list.insertTail(slide);
    });

    return state
      .set('current', slides[0])
      .set('list', list)
      .set('slides', fromJS(slides));
  };
});

store.addInteraction('slides/moveEarlier', async (id) => {
  const slides = store.select('slides');
  const index = slides.findIndex((slide) => slide.id === id);

  const slide = slides[index];
  const temp = slides[index - 1];

  const response = await spanreed.graphql(
    `mutation moveEarlier($slide: ID!, $slideOrder: Int!, $temp: ID!, $tempOrder: Int!) {
      slide: changeSlideOrder(order: $slideOrder, slide: $slide) {
        id
        order
      }
      temp: changeSlideOrder(order: $tempOrder, slide: $temp) {
        id
        order
      }
    }`,
    {
      slide: slide.id,
      slideOrder: slide.order - 1,
      temp: temp.id,
      tempOrder: temp.order + 1,
    }
  );

  return (state) => {
    const slides = state.get('slides');
    const slide = slides.get(response.data.slide.order + 1);
    const temp = slides.get(response.data.temp.order - 1);

    return state.set(
      'slides',
      slides
        .set(index - 1, slide.set('order', slide.get('order') - 1))
        .set(index, temp.set('order', temp.get('order') + 1))
    );
  };
});

store.addInteraction('slides/moveLater', async (id) => {
  const slides = store.select('slides');
  const index = slides.findIndex((slide) => slide.id === id);

  const slide = slides[index];
  const temp = slides[index + 1];

  const response = await spanreed.graphql(
    `mutation moveEarlier($slide: ID!, $slideOrder: Int!, $temp: ID!, $tempOrder: Int!) {
      slide: changeSlideOrder(order: $slideOrder, slide: $slide) {
        id
        order
      }
      temp: changeSlideOrder(order: $tempOrder, slide: $temp) {
        id
        order
      }
    }`,
    {
      slide: slide.id,
      slideOrder: slide.order + 1,
      temp: temp.id,
      tempOrder: temp.order - 1,
    }
  );

  return (state) => {
    const slides = state.get('slides');
    const slide = slides.get(response.data.slide.order - 1);
    const temp = slides.get(response.data.temp.order + 1);

    return state.set(
      'slides',
      slides
        .set(index + 1, slide.set('order', slide.get('order') + 1))
        .set(index, temp.set('order', temp.get('order') - 1))
    );
  };
});

store.addInteraction('slides/remove', async (id) => {
  const response = await spanreed.graphql(
    `mutation remove($slide: ID!) {
      slide: removeSlide(slide: $slide) {
        id
      }
    }`,
    { slide: id }
  );

  // re-order the remaining slides as well
  let order = 0;
  return (state) =>
    state.update('slides', (slides) =>
      slides
        .filter((slide) => slide.get('id') !== response.data.slide.id)
        .map((slide) => slide.set('order', order++))
    );
});

store.addInteraction(
  'slides/viewSlides',
  async ({ business_id, location_id }) => {
    const response = await spanreed.publicGraphql(
      `query viewSlides($business_id: ID!, $location_id: ID!) {
        slides(business_id: $business_id, location_id: $location_id) {
          id
          length
          order
          src: url
        }
      }`,
      { business_id, location_id }
    );
    const slides = response.data.slides;

    return (state) => {
      let list = new LoopedList();

      slides.forEach((slide) => {
        list.insertTail(slide);
      });

      return state
        .set('current', slides[0])
        .set('list', list)
        .set('slides', fromJS(slides));
    };
  }
);

store.addSelector('slide', (state, id) =>
  state
    .get('slides')
    .find((slide) => slide.get('id') === id)
    .toJS()
);
store.addSelector('slides', (state) => state.get('slides').toJS());

export default store;
