import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.img`
  height: 100%;
  width: 100%;
`;

Ad.propTypes = {
  src: string.isRequired,
};

export default function Ad({ src }) {
  return <Wrapper alt="Ad" src={src} />;
}
