import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { darken, lighten, readableColor } from 'polished';
import { node } from 'prop-types';

function Color(color) {
  let object = {
    darker: darken(0.1, color),
    darkest: darken(0.2, color),
    lighter: lighten(0.1, color),
    lightest: lighten(0.2, color),
    string: color,
    textColor: readableColor(color),
  };
  object.toString = () => color;

  return object;
}

let styles = {
  colors: {
    background: '#fcfcf9',
    error: Color('red'),
    gs: {
      white: '#ffffff',
      nearWhite: '#f5f5f5',
      lightGray: '#d0d0d0',
      gray: '#999999',
      darkGray: '#777777',
      nearBlack: '#555555',
      black: '#2d2d2d',
    },
    primary: Color('#3f51b5'),
    secondary: Color('#e2a73b'),
  },
  fonts: {
    family: 'Roboto',
    size: '16px',
    small: {
      size: '14px',
    },
  },
  icons: {
    size: '28px',
    small: {
      size: '20px',
    },
  },
};

styles.fonts.color = styles.colors.gs.nearBlack;
styles.fonts.small.color = styles.colors.gs.nearBlack;

styles.icons.color = styles.colors.gs.nearBlack;

export default styles;

ThemeProvider.propTypes = {
  children: node,
};

export function ThemeProvider({ children }) {
  const theme = createMuiTheme({
    palette: {
      error: {
        contrastText: styles.colors.error.textColor,
        dark: styles.colors.error.darkest,
        light: styles.colors.error.lightest,
        main: styles.colors.error.string,
      },
      primary: {
        contrastText: styles.colors.primary.textColor,
        dark: styles.colors.primary.darkest,
        light: styles.colors.primary.lightest,
        main: styles.colors.primary.string,
      },
      secondary: {
        contrastText: styles.colors.secondary.textColor,
        dark: styles.colors.secondary.darkest,
        light: styles.colors.secondary.lightest,
        main: styles.colors.secondary.string,
      },
    },
    typography: {
      useNextVariants: true,
    },
  });

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
