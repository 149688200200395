import React from 'react';
import styled from 'styled-components';

import { Headline, IconButton, Spacer } from 'components';
import { interact } from 'services/interact';
import styles from 'style-guide';

const StyledHeadline = styled(Headline)`
  margin: 2px 0;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${styles.colors.gs.gray};
  display: flex;
  height: 48px;
  padding: 0 8px;

  > * {
    box-sizing: border-box;
    min-width: 48px;
  }
`;

export default function AppBar() {
  return (
    <Wrapper>
      <StyledHeadline>ShadowVision</StyledHeadline>
      <Spacer />
      {/* <IconButton
        icon="account_circle"
        onClick={() => console.log('profile')}
        small
      >
        Profile
      </IconButton> */}
      <IconButton
        icon="exit_to_app"
        onClick={() => interact('auth/logout')}
        small
      >
        Logout
      </IconButton>
    </Wrapper>
  );
}
