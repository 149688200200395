import { string } from 'prop-types';
import React from 'react';

DaysRemaining.propTypes = {
  date: string.isRequired,
};

export default function DaysRemaining({ date }) {
  let year = date.substr(0, 4);
  let month = date.substr(5, 2);
  let day = date.substr(8, 2);

  let d = new Date(year, month - 1, day);
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  let diff = d.getTime() - today.getTime();
  let days = Math.round(diff / 86400000);

  return (
    <span>
      {days} day{days === 1 ? '' : 's'} remaining.
    </span>
  );
}
