import { List } from '@material-ui/core';
import { node } from 'prop-types';
import React from 'react';

SideBar.propTypes = {
  children: node.isRequired,
};

export default function SideBar({ children }) {
  return <List disablePadding>{children}</List>;
}
