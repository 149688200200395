import { node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import styles from 'style-guide';

const Link = styled.a`
  color: ${styles.colors.primary.string};
  text-decoration: none;
`;

ExternalLink.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
};

export default function ExternalLink({ children, href }) {
  return (
    <Link href={href} rel="noopener noreferrer" target="_blank">
      {children}
    </Link>
  );
}
