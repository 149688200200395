import { interact } from 'services/interact';
import spanreed from 'services/spanreed';
import Store from '../Store';

const store = new Store({
  loginErr: null,
  token: window.localStorage.getItem('authToken'),
});

store.addInteraction('auth/init', () => {
  const token = store.select('token');
  if (token) {
    interact('auth/login');
  }

  return (state) => state.set('token', token);
});

store.addInteraction('auth/login', async ({ email, password } = {}) => {
  if (email && password) {
    const response = await spanreed.post('/auth', { email, password });
    window.localStorage.setItem('authToken', response.token);

    return (state) => state.set('loginErr', null).set('token', response.token);
  } else {
    return (state) => state;
  }
});
store.addInteraction('auth/login/err', () => (state) =>
  state.set('loginErr', 'Incorrect, please try again.')
);

store.addInteraction('auth/logout', () => {
  window.localStorage.removeItem('authToken');
  return (state) => state.set('token', null);
});

export default store;
