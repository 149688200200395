// loop through components and export a function that returns the component
// this makes it so components don't need to be loaded in dependency order
const React = require('react'); // we can't use `import React from 'react';`

const required = {};
const req = require.context('.', true, /\.\/[^/]+\/[^/]+\/index\.js$/);
req.keys().forEach((key) => {
  const componentName = key.replace(/^.+\/([^/]+)\/index\.js/, '$1');
  const componentLookup = (props) => {
    if (!required[componentName]) {
      required[componentName] = req(key).default;
    }

    return React.createElement(required[componentName], props);
  };
  componentLookup.displayName = `${componentName}.lookup`;

  module.exports[componentName] = componentLookup;
});

/*
An advertiser is the one who pays the money to get his advertisements shown.
A publisher is the one who gets money for showing the ads on his site.
An advertiser owns or controls the actual product or service that is being
advertised because he is the one who commands the product advertising.
*/
