import { interact } from 'services/interact';
import spanreed from 'services/spanreed';
import Store from '../Store';

const store = new Store({
  business: null,
});

store.addInteraction('auth/login', () => (state) => {
  interact('business/load');
  return state;
});

store.addInteraction('business/load', async () => {
  const response = await spanreed.graphql(
    `{
      business {
        address1
        address2
        city
        id
        name
        phone
        price
        state
        zip
      }
    }`
  );

  return (state) => state.set('business', response.data.business);
});

export default store;
