import { isEqual, isPlainObject, reduce } from 'lodash';

function Logger(console) {
  this.buffer = [];
  this.console = console;
}

Logger.prototype = {
  /**
   * Adds the difference between two objects
   * @param object from The starting object
   * @param object to The ending object
   */
  addDiff: function(from, to) {
    this.buffer.push({
      args: [' %cchange', 'color: gray; font-weight: lighter;'],
      fn: 'groupCollapsed',
    });

    this.buffer.push({
      args: [' %cprev  ', 'color: #f5912a; font-weight: bold;', from],
      fn: 'info',
    });

    this.buffer.push({
      args: [
        ' %cdiff  ',
        'color: #4caf50; font-weight: bold;',
        this.getDiff(from, to),
      ],
      fn: 'info',
    });

    this.buffer.push({
      args: [' %cnext  ', 'color: #03a9f4; font-weight: bold;', to],
      fn: 'info',
    });

    this.buffer.push({
      args: [],
      fn: 'groupEnd',
    });
  },

  /**
   * Finalizes and flushes the buffer
   */
  flush: function() {
    this.buffer.push({
      args: [],
      fn: 'groupEnd',
    });

    if (process.env.NODE_ENV === 'development') {
      this.buffer.forEach((item) => {
        this.console[item.fn](...item.args);
      });
    }
  },

  /**
   * Gets the difference between two objects
   * @param object from The starting object
   * @param object to The ending object
   * @return object
   */
  getDiff: function(from, to) {
    return reduce(
      to,
      (result, value, key) => {
        if (isPlainObject(from[key])) {
          result[key] = this.getDiff(from[key], value);
          if (!Object.keys(result[key]).length) {
            delete result[key];
          }
        } else if (!isEqual(from[key], value)) {
          result[key] = value;
        }

        return result;
      },
      {}
    );
  },

  /**
   * Gets the current timestamp
   * @return string
   */
  getTimestamp: function() {
    const repeat = (str, times) => new Array(times + 1).join(str);
    const pad = (num, maxLength) =>
      repeat('0', maxLength - num.toString().length) + num;

    const time = new Date();
    const h = time.getHours();
    const m = time.getMinutes();
    const s = time.getSeconds();
    const ms = time.getMilliseconds();

    return `${pad(h, 2)}:${pad(m, 2)}:${pad(s, 2)}.${pad(ms, 3)}`;
  },

  /**
   * Sets up the buffer to log a group
   * @param string interaction The interaction to log
   * @param mixed payload The paylod of the interaction
   */
  start: function(interaction, payload) {
    const err = interaction.substr(-4) === '/err';
    const status = err ? 'err' : 'success';
    const color = err ? '#dc1919;' : '#4caf50;';

    this.buffer = [
      {
        args: [
          '%c' + status + ' %c' + interaction + ' %c' + this.getTimestamp(),
          'color: ' + color + ' font-weight: bold;',
          'color: black; font-weight: bold;',
          'color: gray; font-weight: lighter;',
        ],
        fn: 'groupCollapsed',
      },
      {
        args: [' %cpayload ', 'color: gray; font-weight: lighter;', payload],
        fn: 'info',
      },
    ];
  },
};

export default Logger;
