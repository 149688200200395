import { bool, node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  ${({ absolute }) => (absolute ? 'position: absolute;' : '')}
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${({ visible }) => (visible ? '' : 'pointer-events: none;')}
  transition: opacity 0.3s ease;
`;

Fade.defaultProps = {
  absolute: false,
};

Fade.propTypes = {
  absolute: bool,
  children: node.isRequired,
  visible: bool.isRequired,
};

export default function Fade({ absolute, children, visible }) {
  return (
    <Styled absolute={absolute} visible={visible}>
      {children}
    </Styled>
  );
}
