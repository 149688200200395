class Node {
  data = null;
  next = null;

  constructor(data, next) {
    this.data = data;
    this.next = next;
  }
}

export default class LoopedList {
  head = null;
  tail = null;
  size = 0;

  find(item) {
    if (this.size === 0) {
      return null;
    } else if (this.size === 1) {
      return this.head.data === item ? this.head : null;
    }

    let node = this.head;
    do {
      if (node.data === item) {
        return node;
      }

      node = node.next;
    } while (node !== this.head);

    return null;
  }

  insertHead(item) {
    let node = new Node(item, this.head);
    this.head = node;

    if (this.size === 0) {
      this.tail = node;
    }

    this.tail.next = this.head;
    this.size++;
  }

  insertTail(item) {
    if (!this.tail) {
      return this.insertHead(item);
    }

    let parent = this.find(this.tail.data);
    this.tail = parent.next = new Node(item, this.head);
    this.size++;
  }

  toString() {
    if (!this.head) {
      return 'Empty';
    }

    let str = '';

    let node = this.head;
    do {
      if (str) {
        str += ' ';
      }

      str += node.data;
      node = node.next;
    } while (node !== this.head);

    return str;
  }
}
