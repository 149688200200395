import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import styles from 'style-guide';

const Wrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid ${styles.colors.gs.lightGray};
  border-radius: 4px;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  margin: 12px 12px 16px 0;
  min-width: 100px;
  padding: 12px;
  position: relative;

  > span {
    background-color: ${styles.colors.gs.white};
    font-size: ${styles.fonts.small.size};
    font-weight: normal;
    position: absolute;
    bottom: -8px;
    left: 10px;
    padding: 0 4px;
  }
`;

DollarAmount.propTypes = {
  amount: string.isRequired,
  label: string.isRequired,
};

export default function DollarAmount({ amount, label }) {
  return (
    <Wrapper>
      ${amount}
      <span>{label}</span>
    </Wrapper>
  );
}
