import { isPlainObject } from 'lodash';

import config from 'config';
import { authStore, interact } from 'services/interact';

function Spanreed(urlBase) {
  this.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'spanreed',
  };

  this.tempHeaders = {};
  this.tempTimeout = null;
  this.timeout = 30000;
  this.urlBase = urlBase;

  /* eslint no-loop-func: 0 */
  ['delete', 'get', 'head', 'patch', 'post', 'put'].forEach(
    function(method) {
      this[method] = function(method, url, data) {
        return this.request(method, this.urlBase + url, data);
      }.bind(this, method.toUpperCase());
    }.bind(this)
  );

  this.addHeader = function(key, value) {
    this.headers[key] = value;
  };

  this.graphql = function(query, variables) {
    return this.post('/graphql', { query: query, variables: variables });
  };

  this.publicGraphql = function(query, variables) {
    return this.post('/graphql/public', { query: query, variables: variables });
  };

  this.query = function(options) {
    var query = '';

    for (let param in options) {
      if (isPlainObject(options[param])) {
        Object.keys(options[param]).forEach((value) => {
          let component = options[param][value];

          if (typeof component !== 'string') {
            component = JSON.stringify(component);
          }

          query +=
            '&' +
            param +
            '%5B' +
            value +
            '%5D=' +
            encodeURIComponent(component);
        });
      } else {
        let component = options[param];

        if (typeof component !== 'string') {
          component = JSON.stringify(component);
        }

        query += '&' + param + '=' + encodeURIComponent(component);
      }
    }

    return options ? '?' + query.slice(1) : '';
  };

  this.onUnauthorized = function() {};

  this.removeHeader = function(key) {
    delete this.headers[key];
  };

  this.request = function(method, url, data) {
    return new Promise(
      function(resolve, reject) {
        var xhr = new XMLHttpRequest();

        var timeout = setTimeout(function() {
          reject({
            err: 'Connection Timed Out',
            xhr: xhr,
          });
        }, this.tempTimeout || this.timeout);
        this.tempTimeout = null;

        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            clearTimeout(timeout);
            var code = xhr.status;

            if ((code >= 200 && code < 300) || code === 304 || code === 1223) {
              try {
                resolve(JSON.parse(xhr.responseText));
              } catch (e) {
                resolve(xhr.responseText);
              }
            } else {
              if (code === 401) {
                this.onUnauthorized();
                return reject({
                  code: code,
                  message: 'Unauthorized',
                  xhr: xhr,
                });
              }

              reject({
                code: code,
                message: 'Invalid HTTP Code',
                xhr: xhr,
              });
            }
          }
        }.bind(this);

        xhr.open(method, url);

        var name;
        var headers = {};

        for (name in this.headers) {
          if (this.headers.hasOwnProperty(name)) {
            headers[name] = this.headers[name];
          }
        }

        for (name in this.tempHeaders) {
          if (this.tempHeaders.hasOwnProperty(name)) {
            headers[name] = this.tempHeaders[name];
          }
        }

        for (name in headers) {
          if (headers.hasOwnProperty(name)) {
            xhr.setRequestHeader(name, headers[name]);
          }
        }

        this.tempHeaders = {};

        if (data) {
          xhr.send(JSON.stringify(data));
        } else {
          xhr.send();
        }
      }.bind(this)
    );
  };
}

let spanreed = new Spanreed(config('URLS', 'api'));
export default spanreed;

let init = () => {
  spanreed.onUnauthorized = () => interact('auth/logout');

  let token = authStore.select('token');
  if (token) {
    spanreed.addHeader('Authorization', `Bearer ${token}`);
  }

  authStore.subscribe(() => {
    let token = authStore.select('token');
    if (token) {
      spanreed.addHeader('Authorization', `Bearer ${token}`);
    } else {
      spanreed.removeHeader('Authorization');
    }
  });
};
export { init };
