import React from 'react';
import ReactDOM from 'react-dom';

import './reset.css';

import { interact } from 'services/interact';
import { init as initSpanreed } from 'services/spanreed';

import App from 'components/App';

initSpanreed();
interact('auth/init');

ReactDOM.render(<App />, document.getElementById('root'));
