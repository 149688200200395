import { node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AppTemplate } from 'components';
import styles from 'style-guide';

const Background = styled.div`
  display: flex;
  flex: row no-wrap;
  flex-grow: 1;
`;

const SidebarWrapper = styled.div`
  background-color: ${styles.colors.gs.nearWhite};
  border-right: 1px solid ${styles.colors.gs.gray};
  width: 200px;
`;

const ColumnWrapper = styled.div`
  padding: 16px 32px;
  ${({ big }) => (big ? `width: 884px;` : `flex: 1;`)}
`;

SlidesTemplate.propTypes = {
  column1: node.isRequired,
  column2: node.isRequired,
  sidebar: node.isRequired,
};

export default function SlidesTemplate({ column1, column2, sidebar }) {
  return (
    <AppTemplate>
      <Background>
        <SidebarWrapper>{sidebar}</SidebarWrapper>
        <ColumnWrapper big>{column1}</ColumnWrapper>
        <ColumnWrapper>{column2}</ColumnWrapper>
      </Background>
    </AppTemplate>
  );
}
