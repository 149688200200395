import { bool, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import styles from 'style-guide';

const StyledIcon = styled.div`
  && {
    color: inherit;
    font-size: ${({ small }) =>
      small ? styles.icons.small.size : styles.icons.size};
    height: ${({ small }) =>
      small ? styles.icons.small.size : styles.icons.size};
    transition: color 0.3s;
    width: ${({ small }) =>
      small ? styles.icons.small.size : styles.icons.size};
  }
`;

Icon.defaultProps = {
  small: false,
};

Icon.propTypes = {
  icon: string.isRequired,
  small: bool,
};

export default function Icon({ icon, small }) {
  return (
    <StyledIcon className="material-icons" small={small}>
      {icon}
    </StyledIcon>
  );
}
